import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ocelot-apis"
    }}>{`Ocelot APIs`}</h1>
    <h2 {...{
      "id": "acrs"
    }}>{`ACRs`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`POST /ocelot/acrs
`}</code></pre>
    <h3 {...{
      "id": "body"
    }}>{`Body`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the ACR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`settings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Settings are required for some ACR types`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "attribute-type"
    }}>{`Attribute `}<inlineCode parentName="h4">{`type`}</inlineCode></h4>
    <p>{`Type can have the following values`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`http://ocelot.yanchware.com/acrs/anonymous/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anonymous Context Class Reference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`http://ocelot.yanchware.com/acrs/authenticated/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated Context Class Reference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`http://ocelot.yanchware.com/acrs/strongly-authenticated/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Strongly Authenticated Context Class Reference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`http://ocelot.yanchware.com/acrs/oidc/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open Id Connect Context Class Reference`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "attribute-settings"
    }}>{`Attribute `}<inlineCode parentName="h4">{`settings`}</inlineCode></h4>
    <p>{`Settings is an object that is required exclusively when the `}<inlineCode parentName="p">{`Open Id Connect Context Class Reference`}</inlineCode>{` is specified in
the `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute of the request.`}</p>
    <p>{`In that case settings should have the following attributes:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`authorizationEndpoint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint used to redirect the user to when starting the OIDC flow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tokenEndpoint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint used to redeem an access token in exchange of a code (Code Flow)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`jwksUri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location of the set of public keys used to verify signed tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`userinfoEndpoint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Endpoint returning claims about the authenticated user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clientId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client id assigned to access the Identity Provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`responseType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Must include `}<inlineCode parentName="td">{`code`}</inlineCode>{`, `}<inlineCode parentName="td">{`id_token`}</inlineCode>{` or `}<inlineCode parentName="td">{`token`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`redirectUri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uri where to redirect the customer after successful authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`responseMode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This should always be `}<inlineCode parentName="td">{`form_post`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clientSecretId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the secret stored in the vault used to contact the identity provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This should always be `}<inlineCode parentName="td">{`openid%20email%20profile`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`subjectField`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is used in order to specify which claim from the ID token or User Info object you would like to use as subject for the token issued by Ocelot`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`roleMappingsByRoleName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Map<string, RoleMapping[]>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used to define rules for role mappings`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Important information:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`responseType`}</inlineCode>{` must include `}<inlineCode parentName="li">{`code`}</inlineCode>{` for the authorization code flow. Can also include `}<inlineCode parentName="li">{`id_token`}</inlineCode>{` or `}<inlineCode parentName="li">{`token`}</inlineCode>{` if using the hybrid flow.`}</li>
      <li parentName="ul">{`the value expressed at redirectUri should be parsed by the application that then should forward the information to the `}<inlineCode parentName="li">{`/ocelot/users/logon`}</inlineCode>{` endpoint`}</li>
    </ul>
    <h3 {...{
      "id": "invocation"
    }}>{`Invocation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl https://ocelot.example.com/ocelot/acrs -d @"./create-acr-body.json" -H "Content-Type: application/json" -v --cookie OSS=xxx
`}</code></pre>
    <p>{`create-acr-body.json`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "authorizationEndpoint": "https://login.microsoftonline.com/{tenant}/oauth2/v2.0/authorize",
    "tokenEndpoint": "https://login.microsoftonline.com/{tenant}/oauth2/v2.0/token",
    "jwksUri": "https://login.microsoftonline.com/{tenant}/discovery/v2.0/keys",
    "userinfoEndpoint": "https://graph.microsoft.com/oidc/userinfo",
    "clientId": "6731de76-14a6-49ae-97bc-6eba6914391e",
    "responseType": "code",
    "redirectUri": "http://localhost/myapp/",
    "responseMode": "form_post",
    "clientSecretId": "clientSecretOnTheVault",
    "scope": "openid%20email%20profile",
    "subjectField": "preferred_username"
}
`}</code></pre>
    <p>{`Response example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`HTTP/1.1 201 Created
Location: ocelot/acrs/5528f641-6e81-4c74-ab36-ca6534f0da85
`}</code></pre>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`PUT /ocelot/acrs/{id}
`}</code></pre>
    <h3 {...{
      "id": "path-variables"
    }}>{`Path variables`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The id of the ACR being updated.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "body-1"
    }}>{`Body`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of the ACR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`settings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Settings are required for some ACR types`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The attributes `}<inlineCode parentName="p">{`type`}</inlineCode>{` and `}<inlineCode parentName="p">{`settings`}</inlineCode>{` of the body have been documented above.`}</p>
    <h3 {...{
      "id": "invocation-1"
    }}>{`Invocation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl https://ocelot.example.com/ocelot/acrs -X PUT -d @"./update-acr-body.json" -H "Content-Type: application/json" -v --cookie OSS=xxx
`}</code></pre>
    <p>{`Response example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`HTTP/1.1 200 OK
`}</code></pre>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`GET /ocelot/acrs?page={pageNumber}
`}</code></pre>
    <h3 {...{
      "id": "path-variables-1"
    }}>{`Path variables`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pageNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of the page starting with 1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`This method allows the called to list all ACRs in the system. The results are paginated with page size equal to `}<inlineCode parentName="p">{`50`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "invocation-2"
    }}>{`Invocation`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl https://ocelot.example.com/ocelot/acrs?page=1 -v --cookie OSS=xxx
`}</code></pre>
    <p>{`Response example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{``}</code></pre>
    <hr />
    <pre><code parentName="pre" {...{
        "className": "language-plaintext"
      }}>{`GET /ocelot/acrs/{id}
`}</code></pre>
    <h3 {...{
      "id": "path-variables-2"
    }}>{`Path variables`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The id of the ACR being updated.`}</td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl https://ocelot.example.com/ocelot/acrs/5528f641-6e81-4c74-ab36-ca6534f0da85 -v --cookie OSS=xxx
`}</code></pre>
    <p>{`Response example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{``}</code></pre>
    <h2 {...{
      "id": "clients"
    }}>{`Clients`}</h2>
    <h2 {...{
      "id": "login"
    }}>{`Login`}</h2>
    <h2 {...{
      "id": "operators"
    }}>{`Operators`}</h2>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <h2 {...{
      "id": "roles"
    }}>{`Roles`}</h2>
    <h2 {...{
      "id": "sessions"
    }}>{`Sessions`}</h2>
    <h2 {...{
      "id": "users"
    }}>{`Users`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      